// Initialize Custom select.
document.addEventListener('DOMContentLoaded', () => {
    const selects = document.querySelectorAll('.customSelect')
    selects.forEach(select => {
        new Choices(select, {
            searchEnabled: false,
            itemSelectText: ''
        })
    })
})
