// Remove unneeded 'aria-hidden' attribute from carousel items.
document.addEventListener('DOMContentLoaded', () => {
  const items = document.querySelectorAll('.location-carousel-item');

  if (items.length > 0) {
    items.forEach((item) => {
      item.removeAttribute('aria-hidden');
    });
  }
});
