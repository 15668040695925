// Mobile primary menu select.
document.querySelector('.nav-dropdown-toggle').addEventListener('click', function () {
  this.classList.toggle('active');
  const toggleArrow = this.querySelector('svg');
  if (toggleArrow) {
      toggleArrow.classList.toggle('active');
  }
  const dropDownMenu = document.querySelector('.nav-dropdown-menu');
  dropDownMenu.classList.toggle('active');
});

// Mobile menu toggle.
document.getElementById('menuToggle').addEventListener('click', function () {
  const menuIcon = document.getElementById('menuIcon');
  const closeIcon = document.getElementById('closeIcon');
  const ancillaryNav = document.querySelector('.nav-mobile-ancillary');
  const secondaryMenu = document.getElementById('navMobileSecondary');
  const navMobile = document.getElementById('navMobile');
  const alertBanner = document.querySelector('.alert-banner');
  const navContainer = document.querySelector('.nav-mobile-container');

  // Scroll to top when opening menu
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });

  navMobile.classList.toggle('opened');
  ancillaryNav.classList.toggle('hidden');
  secondaryMenu.classList.toggle('active');

  // Set the top position of the secondary menu dynamically.
  let offsetTop = 0;

  // Alert banner height (if it exists and it's not hidden).
  if (alertBanner && window.getComputedStyle(alertBanner).display !== 'none') {
      offsetTop += alertBanner.offsetHeight;
  }

  // Nav height.
  if (navContainer) {
      offsetTop += navContainer.offsetHeight + 3; // 3 is the border-top.
  }

  // Set the top position of the secondary menu.
  secondaryMenu.style.top = `${offsetTop}px`;

  // Set height to allow scrolling through all of navMobileSecondary content
  const availableHeight = window.innerHeight - offsetTop;
  secondaryMenu.style.height = `${availableHeight}px`;

  // Stop body from scrolling when the mobile menu is open.
  if (navMobile.classList.contains('opened')) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
  } else {
      document.body.style.overflow = '';
      document.body.style.height = '';
  }

  if (menuIcon.style.display !== 'none') {
      menuIcon.style.display = 'none';
      closeIcon.style.display = 'inline';
  } else {
      closeIcon.style.display = 'none';
      menuIcon.style.display = 'inline';
  }
});

// Submenus.
document.querySelectorAll('.nav-mobile-secondary-item.is-dropdown').forEach(item => {
  item.addEventListener('click', function () {
      this.classList.toggle('active');
      const dropdownOpen = this.querySelector('.dropdown-toggle-open');
      const dropdownClose = this.querySelector('.dropdown-toggle-close');

      if (dropdownOpen && dropdownClose) {
          dropdownOpen.classList.toggle('hidden');
          dropdownClose.classList.toggle('hidden');
      }

      const navDropdownMenu = this.querySelector('.nav-dropdown-menu-secondary');
      const languageDropdownMenu = this.querySelector('.nav-dropdown-menu-secondary-language');
      if (navDropdownMenu) {
          navDropdownMenu.classList.toggle('hidden');
      } else if (languageDropdownMenu) {
          languageDropdownMenu.classList.toggle('hidden');
      }

      document.querySelectorAll('.nav-mobile-secondary-item').forEach(otherItem => {
          if (otherItem !== this) {
              const otherDropdownLanguage = otherItem.querySelector('.nav-dropdown-menu-secondary-language');
              if (!languageDropdownMenu) {
                  otherItem.classList.toggle('hidden');
                  otherItem.classList.remove('active');
              }

              const otherDropdownOpen = otherItem.querySelector('.dropdown-toggle-open');
              const otherDropdownClose = otherItem.querySelector('.dropdown-toggle-close');
              if (otherDropdownOpen && otherDropdownClose) {
                  otherDropdownOpen.classList.add('hidden');
                  otherDropdownClose.classList.remove('hidden');
              }
          }
      });
  });

  // Prevent submenu click from closing the dropdown.
  const submenus = item.querySelectorAll('.nav-dropdown-menu-secondary a, .nav-dropdown-menu-secondary-language a');
  submenus.forEach(submenu => {
      submenu.addEventListener('click', function(event) {
          event.stopPropagation();
      });
  });
});

document.addEventListener("DOMContentLoaded", function () {
  // Desktop Navigation
  const dropdownToggles = document.querySelectorAll(
    ".nav-dropdown-toggle-desktop"
  );
  let openDropdowns = [];

  if (dropdownToggles) {
    dropdownToggles.forEach((toggle) => {
      const menu = toggle.nextElementSibling;
      if (!menu) return;

      toggle.addEventListener("focus", () => {
        openDropdown(menu, toggle);
      });

      toggle.addEventListener("blur", (event) => {
        if (!menu.contains(event.relatedTarget)) {
          closeDropdown(menu, toggle);
        }
      });

      toggle.addEventListener("keydown", (event) => {
        switch (event.key) {
          case "Enter":
          case " ":
            event.preventDefault();
            toggleDropdown(menu, toggle);
            if (menu.style.opacity === "1") {
              const firstMenuItem = menu.querySelector("a");
              if (firstMenuItem) firstMenuItem.focus();
            }
            break;

          case "Escape":
            closeDropdown(menu, toggle);
            toggle.focus();
            break;

          case "ArrowDown":
            event.preventDefault();
            openDropdown(menu, toggle);
            const firstMenuItem = menu.querySelector("a");
            if (firstMenuItem) firstMenuItem.focus();
            break;
        }
      });

      const menuItems = menu.querySelectorAll("a");
      menuItems.forEach((item, index) => {
        item.addEventListener("focus", () => {
          if (!openDropdowns.includes(menu)) {
            openDropdown(menu, toggle);
          }
        });

        item.addEventListener("blur", (event) => {
          if (
            !menu.contains(event.relatedTarget) &&
            event.relatedTarget !== toggle
          ) {
            setTimeout(() => {
              if (
                !menu.contains(document.activeElement) &&
                document.activeElement !== toggle
              ) {
                closeDropdown(menu, toggle);
              }
            }, 0);
          }
        });

        item.addEventListener("keydown", (event) => {
          switch (event.key) {
            case " ": // Add space key handler
            case "Enter":
              event.preventDefault();
              item.click(); // Trigger the link
              break;

            case "ArrowUp":
              event.preventDefault();
              if (index === 0) {
                toggle.focus();
              } else {
                menuItems[index - 1].focus();
              }
              break;

            case "ArrowDown":
              event.preventDefault();
              if (index < menuItems.length - 1) {
                menuItems[index + 1].focus();
              }
              break;

            case "Escape":
              event.preventDefault();
              closeDropdown(menu, toggle);
              toggle.focus();
              break;

            case "Tab":
              if (!event.shiftKey && index === menuItems.length - 1) {
                closeDropdown(menu, toggle);
              } else if (event.shiftKey && index === 0) {
                closeDropdown(menu, toggle);
                toggle.focus();
              }
              break;
          }
        });
      });
    });

    // Close dropdowns when clicking outside
    document.addEventListener("click", (event) => {
      if (!event.target.closest(".nav-dropdown-desktop")) {
        openDropdowns.forEach((menu) => {
          const toggle = menu.previousElementSibling;
          if (toggle) closeDropdown(menu, toggle);
        });
      }
    });

    // Handle escape key globally for desktop dropdowns
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape" && openDropdowns.length > 0) {
        openDropdowns.forEach((menu) => {
          const toggle = menu.previousElementSibling;
          if (toggle) {
            closeDropdown(menu, toggle);
            toggle.focus();
          }
        });
      }
    });
  }

  // Mobile Navigation
  const menuToggle = document.getElementById("menuToggle");

  if (menuToggle) {
    menuToggle.addEventListener("keydown", function (event) {
      if (event.key === "Enter" || event.key === " ") {
        event.preventDefault();
        menuToggle.click();
      }
    });
  }

  // Mobile dropdown setup
  const mobileDropdowns = document.querySelectorAll(
    ".nav-mobile-secondary-item"
  );
  const navMobileSecondary = document.getElementById("navMobileSecondary");

  if (mobileDropdowns) {
    const searchInput = navMobileSecondary.querySelector(
      '.search-form input[type="text"]'
    );

    if (searchInput) {
      searchInput.addEventListener("keydown", function (event) {
        if (!event.shiftKey && event.key === "Tab") {
          closeMobileMenu(navMobileSecondary);
        }
      });
    }

    document.addEventListener("keydown", function (event) {
      const targetElement = event.target;

      if (event.code === "Escape") {
        const navMobile = document.getElementById("navMobile");
        if (navMobile && navMobile.classList.contains("opened")) {
          closeMobileMenu(navMobile);
        }
      }

      // Handling Enter and Space keys
      if (event.code === " ") {
        event.preventDefault();

        if (
          targetElement.classList.contains("dropdown-menu") ||
          targetElement.closest(".dropdown-menu")
        ) {
          toggleDropdownMenu(targetElement);
        } else if (targetElement) {
          targetElement.click();
        }
      }
    });

    mobileDropdowns.forEach((item) => {
      const dropdownMenu = item.querySelector(
        ".nav-dropdown-menu-secondary, .nav-dropdown-menu-secondary-language"
      );
      if (!dropdownMenu) return; // Skip if not a dropdown item

      // Keyboard navigation for mobile dropdowns
      item.addEventListener("keydown", function (event) {
        const dropdownMenu = this.querySelector(
          ".nav-dropdown-menu-secondary, .nav-dropdown-menu-secondary-language"
        );
        if (!dropdownMenu) return;

        const links = dropdownMenu.querySelectorAll("a");
        let focusedIndex = Array.from(links).indexOf(document.activeElement);

        switch (event.key) {
          case "Enter":
          case " ":
            event.preventDefault();
            this.click();
            if (!dropdownMenu.classList.contains("hidden")) {
              const firstLink = links[0];
              if (firstLink) firstLink.focus();
            } else if (document.activeElement.tagName === "A") {
              document.activeElement.click();
            }
            break;

          case "Escape":
            if (!dropdownMenu.classList.contains("hidden")) {
              this.click();
              this.focus();
            }
            break;

          case "ArrowDown":
            event.preventDefault();
            if (dropdownMenu.classList.contains("hidden")) {
              this.click();
              const firstLink = links[0];
              if (firstLink) firstLink.focus();
            } else if (focusedIndex >= 0 && focusedIndex < links.length - 1) {
              links[focusedIndex + 1].focus(); // Move to the next link
            }
            break;

          case "ArrowUp":
            event.preventDefault();
            if (!dropdownMenu.classList.contains("hidden") && focusedIndex > 0) {
              links[focusedIndex - 1].focus(); // Move to the previous link
            }
            break;
        }
      });

      // Setup keyboard navigation for dropdown menu items
      const menuLinks = dropdownMenu.querySelectorAll("a");
      menuLinks.forEach((link, index) => {
        link.addEventListener("keydown", function (event) {
          switch (event.key) {
            case "ArrowUp":
              event.preventDefault();
              if (index === 0) {
                item.focus();
              } else {
                menuLinks[index - 1].focus();
              }
              break;

            case "ArrowDown":
              event.preventDefault();
              if (index < menuLinks.length - 1) {
                menuLinks[index + 1].focus();
              }
              break;

            case "Escape":
              event.preventDefault();
              item.click();
              item.focus();
              break;

            case "Tab":
              if (!event.shiftKey && index === menuLinks.length - 1) {
                item.click();
              } else if (event.shiftKey && index === 0) {
                item.click();
                item.focus();
              }
              break;
          }
        });
      });
    });
  }

  // Helper functions for desktop dropdowns
  function openDropdown(menu, toggle) {
    menu.classList.add('force-open-dropdown');

    if (!openDropdowns.includes(menu)) {
      openDropdowns.push(menu);
    }
  }

  function closeDropdown(menu, toggle) {
    menu.classList.remove('force-open-dropdown');

    openDropdowns = openDropdowns.filter((openMenu) => openMenu !== menu);
  }

  function toggleDropdown(menu, toggle) {
    if (menu.classList.contains('force-open-dropdown')) {
      closeDropdown(menu, toggle);
    } else {
      openDropdown(menu, toggle);
    }
  }

  function closeMobileMenu(navMobileSecondary) {
    const menuToggle = document.getElementById("menuToggle");
    if (
      menuToggle &&
      document.getElementById("navMobile").classList.contains("opened")
    ) {
      // Close all dropdowns first
      navMobileSecondary
        .querySelectorAll(
          ".nav-dropdown-menu-secondary:not(.hidden), .nav-dropdown-menu-secondary-language:not(.hidden)"
        )
        .forEach((menu) => {
          menu.classList.add("hidden");
        });

      navMobileSecondary
        .querySelectorAll('[aria-expanded="true"]')
        .forEach((item) => {
          item.setAttribute("aria-expanded", "false");
        });

      menuToggle.click();
    }
  }
});
