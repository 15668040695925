// GLOBAL STARTER DEPENDENCIES
import $ from 'jquery'
import 'tether'
import 'bootstrap'
import 'timepicker'
import 'bootstrap-datepicker'
import 'holderjs'
import 'axios'

import Choices from 'choices.js'

import '../node_modules/flickity-imagesloaded/flickity-imagesloaded.js'
import '../node_modules/flickity/dist/flickity.pkgd.js'

// import Flickity from 'flickity'

import 'moment'
import 'vue'
export {Slider, CraftVue} from './starters'

// Navigation.
import '/web/scripts/navigation.js'

// Form
import '/web/scripts/form.js'

// Alert.
import '/web/scripts/alert.js'

// Carousel.
import '/web/scripts/carousel.js'

// Select.
import '/web/scripts/select.js'

// PROJECT SPECIFIC DEPENDENCIES





// ****************************************************************************
// GLOBAL JAVASCRIPT (COMPILES INTO SCRIPTS-BUNDLE.JS AND SERVED ON EVERY PAGE)
// ****************************************************************************
