import Flickity from 'flickity'

export class Slider {

	constructor(selector, opts) {
		const flkty = new Flickity(selector, opts)		
	}

}

